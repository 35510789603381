import type React from 'react';
import Section from '../../../general/Section/Section';
import VimeoVideo from '../../../general/Video/VimeoVideo';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';
import styles from './Specifications.module.scss';

const Specifications: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { description, productPageTitle, specifications, vimeoId } = useTypedSelector(({ specifications }) => specifications);

    return (
        <Section hasNoBottomPadding>
            <div id="specifications" className={styles.Specifications}>
                <div className="content mb-6 content--fluid p-0">
                    <h2 className="h3"><Translation pageString="productinformatie" stringId="gift_specifications" /></h2>
                </div>
                <div className="is-grid has-col-2">
                    <div>
                        <div className="p product-specifications" dangerouslySetInnerHTML={{ __html: specifications }} />
                        {!!vimeoId && (
                            <div className="product-video-container mt-8">
                                <VimeoVideo
                                    key={vimeoId}
                                    vimeoId={String(vimeoId)}
                                    aspectRatio="16:9"
                                />
                            </div>)
                        }
                    </div>
                    <div>
                        <h2 dangerouslySetInnerHTML={{ __html: productPageTitle }} />
                        <div className="p" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Specifications;
